import {
  ApolloCache,
  ApolloClient,
  ApolloError,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MutationFunctionOptions,
  MutationResult,
} from '@apollo/client';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {
  AddAttachmentDocument,
  AddAttachmentMutation,
  AddAttachmentMutationVariables,
  Attachment,
  DeleteAttachmentsMutation,
  DeleteAttachmentsMutationVariables,
  GenerateZippedInvoiceAttachmentsMutation,
  GenerateZippedInvoiceAttachmentsMutationVariables,
  UpdateAttachmentsAccessibilityMutation,
  UpdateAttachmentsAccessibilityMutationVariables,
  useDeleteAttachmentsMutation,
  useGenerateZippedInvoiceAttachmentsMutation,
  useGetAttachmentLinkLazyQuery,
  useGetAttachmentsLazyQuery,
  useSendInvoiceAttachmentsEmailMutation,
  useUpdateAttachmentsAccessibilityMutation,
} from '@symfonia-ksef/graphql';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {createUploadLink} from 'apollo-upload-client';
import {environment} from '../../../environments/environment.prod';
import {getBarerToken} from '../../root/middlewares';
import {downloadFromLink} from '../utils/utils';
import {FilehubFile} from '../modals/FilehubAddAttachmentModal';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {addAlert} from '../../../services/helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';

export const useFilehubMethods = (
  state: IFilehubState,
  clearSelection?: () => void,
): {
  deleteAttachmentAction: (attachmentIds: string[]) => Promise<void>;
  downloadAttachmentAction: (id: string) => Promise<void>;
  downloadAttachmentsAction: (
    attachmentIds: string[],
    invoiceId: string,
    isXML: boolean,
    isPDF: boolean,
  ) => Promise<void>;
  getAttachmentsAction: (invoiceId: string) => Promise<void>;
  changeAttachmentStatusAction: () => void;
  sendAttachmentLinkAction: (isXML: boolean, isPDF: boolean) => Promise<void>;
} => {
  const {
    company: {companyId},
  } = earchiveState;

  const [changeAttachmentStatus]: [
    (
      options?: MutationFunctionOptions<
        UpdateAttachmentsAccessibilityMutation,
        UpdateAttachmentsAccessibilityMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<UpdateAttachmentsAccessibilityMutation>>,
    MutationResult<UpdateAttachmentsAccessibilityMutation>,
  ] = useUpdateAttachmentsAccessibilityMutation({
    context: {envId: companyId},
  });

  const changeAttachmentStatusAction = async () => {
    const newPublicAttachments = state.attachments.filter(attachment => attachment.IsPublic);
    const newPrivateAttachments = state.attachments.filter(attachment => !attachment.IsPublic);

    const newPrivateIds = newPrivateAttachments.map(attachment => attachment.Id);
    const newPublicIds = newPublicAttachments.map(attachment => attachment.Id);

    const hasNewPrivateIds = newPrivateIds.length > 0;
    const hasNewPublicIds = newPublicIds.length > 0;

    try {
      if (hasNewPublicIds) {
        const {data} = await changeAttachmentStatus({
          variables: {
            Ids: newPublicIds,
            InvoiceId: state.invoiceId,
            IsPublic: true,
          },
        });
        state.setSuccessfulMultipleActionsRequest(data?.UpdateAttachmentsAccessibility.IsDone as boolean | null);
      }

      if (hasNewPrivateIds) {
        const {data} = await changeAttachmentStatus({
          variables: {
            Ids: newPrivateIds,
            InvoiceId: state.invoiceId,
            IsPublic: false,
          },
        });
        state.setSuccessfulMultipleActionsRequest(data?.UpdateAttachmentsAccessibility.IsDone as boolean | null);
      }

      state.setAreFooterButtonsShown(false);
    } catch (err) {
      console.error(err);
      state.setSuccessfulMultipleActionsRequest(false);
    } finally {
      state.setSearch('');
      setTimeout(() => {
        state.setSuccessfulMultipleActionsRequest(null);
      }, 5000);
    }
  };

  const [deleteAttachment]: [
    (
      options?: MutationFunctionOptions<
        DeleteAttachmentsMutation,
        DeleteAttachmentsMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<DeleteAttachmentsMutation>>,
    MutationResult<DeleteAttachmentsMutation>,
  ] = useDeleteAttachmentsMutation({context: {envId: companyId}});

  const deleteAttachmentAction = async (attachmentIds: string[]): Promise<void> => {
    state.setSuccessfulDeleteRequest(null);

    try {
      const {data} = await deleteAttachment({
        variables: {
          Id: attachmentIds,
          InvoiceId: state.invoiceId,
        },
      });

      if (clearSelection) {
        clearSelection();
      }

      await getAttachmentsAction(state.invoiceId);
      state.setAreFooterButtonsShown(false);
      state.setSuccessfulDeleteRequest(data?.DeleteAttachment.IsDone as boolean | null);
      state.setDeletedAttachmentsList(state.deleteAttachmentsList);
    } catch (err) {
      console.error(err);
      state.setSuccessfulDeleteRequest(false);
    } finally {
      state.setSearch('');
      setTimeout(() => {
        state.setSuccessfulDeleteRequest(null);
      }, 5000);
    }
  };

  const [downloadAttachment] = useGetAttachmentLinkLazyQuery({context: {envId: companyId}});

  const downloadAttachmentAction = async (attachmentId: string): Promise<void> => {
    try {
      const {data} = await downloadAttachment({
        variables: {
          Id: attachmentId,
        },
      });

      const {Link: downloadLink} = data?.GetAttachmentLink || {};

      if (downloadLink) {
        state.setSuccessfulDownloadSingleAttachmentRequest(true);
        downloadFromLink(downloadLink, 'Załączniki');
      } else {
        throw new Error('No download link available');
      }
    } catch (err) {
      console.error(err);

      state.setSuccessfulDownloadSingleAttachmentRequest(false);
    } finally {
      state.setSearch('');
      setTimeout(() => {
        state.setSuccessfulDownloadSingleAttachmentRequest(null);
      }, 5000);
    }
  };

  const [downloadAttachments]: [
    (
      options?: MutationFunctionOptions<
        GenerateZippedInvoiceAttachmentsMutation,
        GenerateZippedInvoiceAttachmentsMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<GenerateZippedInvoiceAttachmentsMutation>>,
    MutationResult<GenerateZippedInvoiceAttachmentsMutation>,
  ] = useGenerateZippedInvoiceAttachmentsMutation({context: {envId: companyId}});

  const downloadAttachmentsAction = async (
    attachmentIds: string[],
    invoiceId: string,
    isPDF: boolean,
    isXML: boolean,
  ): Promise<void> => {
    try {
      const {data} = await downloadAttachments({
        variables: {
          Ids: attachmentIds,
          IncludeXML: isXML,
          IncludePDF: isPDF,
          InvoiceId: invoiceId,
        },
      });

      const {Link: downloadLink, FileName: fileName} = data?.GenerateZippedInvoiceAttachments || {};

      if (downloadLink) {
        state.setZippedAttachmentsFileName(fileName || '');
        state.setSuccessfulDownloadMultipleAttachmentsRequest(true);
        downloadFromLink(downloadLink, fileName || 'Załączniki');
      } else {
        throw new Error('No download link available');
      }
    } catch (err) {
      console.error(err);
      state.setSuccessfulDownloadMultipleAttachmentsRequest(false);
    } finally {
      state.setSearch('');
      setTimeout(() => {
        state.setSuccessfulDownloadMultipleAttachmentsRequest(null);
      }, 5000);
    }
  };

  const [getAttachments] = useGetAttachmentsLazyQuery({context: {envId: companyId}});

  const getAttachmentsAction = async (invoiceId: string): Promise<void> => {
    state.setShowGetAttachmentsError(false);

    try {
      const {data, error} = await getAttachments({
        variables: {
          InvoiceId: invoiceId,
        },
      });

      if (error) {
        throw new Error('Something went wrong');
      }

      const filteredArray = data?.GetAttachments.Attachments?.filter((item): item is Attachment => item !== undefined);

      data && state.setAttachments(filteredArray!);
      data && state.setAttachmentsWithoutFilters(filteredArray!);
    } catch (err) {
      state.setShowGetAttachmentsError(true);
      console.error(err);
    }
  };

  const [sendAttachmentLink] = useSendInvoiceAttachmentsEmailMutation({context: {envId: companyId}});

  const sendAttachmentLinkAction = async (isXML: boolean, isPDF: boolean): Promise<void> => {
    const isPurchaseInvoice = location.pathname.includes('/documents/purchase');

    try {
      const {data} = await sendAttachmentLink({
        variables: {
          EmailAddresses: state.emailListToSend,
          IncludePDF: isPDF,
          IncludeXML: isXML,
          InvoiceId: state.invoiceId,
          Message: state.sendByEmailText,
          ShareName: `${intl.formatMessage({id: isPurchaseInvoice ? Tr.invoicePURCHASE : Tr.invoiceSALES})}, ${state.invoiceNumber}`,
        },
      });

      if (data?.SendInvoiceAttachmentsEmail.IsDone) {
        addAlert({id: Tr.filehubMessageSentToReceiver, color: ToastVariant.SUCCESS, duration: 10000});
      } else {
        throw new Error();
      }
    } catch (err) {
      console.error(err);
      addAlert({id: Tr.filehubMessageNotSentToReceiver, duration: 10000});
    } finally {
      state.setEmailListToSendList([]);
      state.setIsSendAttachmentByLinkModalOpen(false);
    }
  };

  return {
    deleteAttachmentAction,
    downloadAttachmentsAction,
    downloadAttachmentAction,
    getAttachmentsAction,
    changeAttachmentStatusAction,
    sendAttachmentLinkAction,
  };
};

export const addAttachments = async (
  files: FilehubFile[],
  state: IFilehubState,
  setFiles: React.Dispatch<React.SetStateAction<FilehubFile[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setIsLoading(true);
  state.setErrorSizeFileList([]);

  const uploadLink = createUploadLink({
    uri: environment.apiUrl,
    headers: {
      Authorization: getBarerToken(),
      'GraphQL-preflight': '1',
      Environmentid: earchiveState.company.companyId!,
    },
  });

  const client = new ApolloClient({
    link: uploadLink,
    cache: new InMemoryCache(),
  });

  const addedFiles = <string[]>[];
  const errorFileSize = <string[]>[];

  try {
    for (const file of files) {
      const {
        file: {name: fileName},
      } = file;

      try {
        const {data} = await client.mutate<AddAttachmentMutation, AddAttachmentMutationVariables>({
          mutation: AddAttachmentDocument,
          variables: {
            input: {
              File: file.file,
              IsPublic: false,
              InvoiceId: state.invoiceId,
            },
          },
        });

        if (data) {
          addedFiles.push(data?.AddAttachment.FileName as string);
        }
      } catch (err) {
        if (err instanceof ApolloError && err.graphQLErrors) {
          const {code} = err.graphQLErrors[0].extensions;

          if (code === 'FILEHUB_NOT_ENOUGH_SPACE') {
            errorFileSize.push(fileName);
          } else {
            state.setSuccessfulAddRequest(false);
          }
        } else {
          console.error(err);
          state.setSuccessfulAddRequest(false);
        }
      }
    }

    if (addedFiles.length > 0) {
      state.setSuccessfulAddRequest(true);
      setFiles([]);
      state.setAddAttachmentsList(addedFiles);
    }
    state.setErrorSizeFileList(errorFileSize);
  } catch (err) {
    console.log(err);
  } finally {
    state.setSearch('');
    setIsLoading(false);
    setTimeout(() => {
      state.setSuccessfulAddRequest(null);
    }, 5000);
  }
};
