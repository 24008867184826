import clsx from 'clsx';
import {Children, FC, ReactElement, useEffect, useMemo, useRef} from 'react';
import {twMerge} from 'tailwind-merge';
import {TableRowProps} from './TableRow';
import {TableHeader, TableHeaderProps} from './components/TableHeader';
import {TableSortDirection} from './Table.types';
import {TableRowReflection} from './components/TableRowReflection';
import {TestableElement} from '../../external/types';

export {TableSortDirection} from './Table.types';

export enum TableRowHeight {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export type TableSort = {
  name: string;
  direction: TableSortDirection;
};

export type TableProps = {
  sortBy?: TableSort;
  onSortChange?: (sortBy: TableSort) => void;
  header: TableHeaderProps[];
  headerClassName?: string;
  headerBoxClassName?: string;
  innerClassName?: string;
  children: ReactElement<TableRowProps> | ReactElement<TableRowProps>[];
  className?: string;
  tableRowHeight?: TableRowHeight;
  lastColumnSticky?: boolean;
  headerSticky?: boolean;
} & TestableElement;

export const Table: FC<TableProps> = ({
  header,
  headerClassName = '',
  headerBoxClassName = '',
  innerClassName = '',
  children,
  tableRowHeight = TableRowHeight.LG,
  className = undefined,
  sortBy = undefined,
  onSortChange = () => undefined,
  lastColumnSticky = false,
  testId = undefined,
  headerSticky = false,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const headerBoxRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    header.forEach(hr => {
      if (header.filter(_hr => _hr.name === hr.name).length > 1) {
        throw new Error('Symfonia: Nazwa kolumny tabeli musi być unikatowa');
      }
    });
  }, [header]);

  const rowStyles = {
    [TableRowHeight.SM]: {
      header: 'text-[12px]',
      column: 'min-h-[32px]',
      cell: 'text-[14px]',
    },
    [TableRowHeight.MD]: {
      header: 'text-[14px]',
      column: 'min-h-[40px]',
      cell: 'text-[14px]',
    },
    [TableRowHeight.LG]: {
      header: 'text-[16px]',
      column: 'min-h-[48px]',
      cell: 'text-[16px]',
    },
  };

  const widthClassNames: string[] = header.map(({width}) =>
    width !== undefined && /^w-.*?/.test(width) ? width : `wp-${Math.round(100 / header.length)}`,
  );

  const styles = {
    component: twMerge(clsx('border border-solid border-grey-100 rounded-[2px]', className)),
    table: twMerge(clsx('flex min-w-full w-full flex-col', innerClassName)),
    column: `grow shrink leading-[24px] pl-[16px] items-center inline-flex ${rowStyles[tableRowHeight].column}`,
    header: twMerge(
      clsx(
        `w-fit min-w-full flex bg-grey-50 font-quicksand font-bold`,
        rowStyles[tableRowHeight].header,
        headerClassName,
      ),
    ),
    headerStickyLastColumnElement:
      'absolute top-0 right-0 bg-grey-50 before:shadow-[-20px_0_10px_-10px_inset] before:shadow-grey-50 before:h-full before:right-[calc(100%-1px)] before:w-[40px] before:absolute',
    rowStickyLastColumn:
      'last:sticky last:right-0 last:bg-white before:last:shadow-[-20px_0_10px_-10px_inset] before:last:shadow-white before:last:h-full before:last:right-[calc(100%-1px)] before:last:w-[40px] before:last:absolute',
    cell: rowStyles[tableRowHeight].cell,
  };

  const rows = useMemo(
    () =>
      Children.map(children, child => (
        <TableRowReflection
          {...child.props}
          widths={widthClassNames}
          columnClassName={twMerge(
            clsx(
              styles.column,
              styles.cell,
              {[styles.rowStickyLastColumn]: lastColumnSticky},
              child.props.columnClassName,
            ),
          )}
          header={header}
        />
      )),
    [children],
  );

  const handleSortChange = (name: string, defaultDirection: TableSortDirection) => {
    if (!onSortChange) {
      return;
    }
    if (sortBy && sortBy.name === name) {
      if (sortBy.direction === TableSortDirection.ASC) {
        onSortChange({name, direction: TableSortDirection.DESC});
      } else {
        onSortChange({name, direction: TableSortDirection.ASC});
      }
    } else {
      onSortChange({name, direction: defaultDirection});
    }
  };

  const handleScrollX = () => {
    headerRef.current!.style.transform = `translateX(-${contentRef.current!.scrollLeft || 0}px)`;
    headerBoxRef.current!.style.clipPath = `polygon(0% 0%, ${contentRef.current!.offsetWidth}px 0%, ${contentRef.current!.offsetWidth}px 100%, 0% 100%)`;
  };

  useEffect(() => {
    const callback = () => {
      if (headerRef.current !== null && headerBoxRef.current !== null) {
        handleScrollX();
      }
    };
    const ro = new ResizeObserver(callback);
    ro.observe(contentRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);

  useEffect(handleScrollX, []);

  const renderHeaderRow = (index: number) => {
    const {className: headerColClassName, ...headerProps} = header[index];
    const direction = sortBy !== undefined && headerProps.sortable ? sortBy.direction : undefined;

    return (
      <TableHeader
        key={headerProps.name}
        className={clsx(
          styles.column,
          widthClassNames[index],
          {
            [styles.headerStickyLastColumnElement]: lastColumnSticky && index === header.length - 1,
          },
          headerColClassName,
        )}
        {...headerProps}
        onSortChange={() =>
          handleSortChange(headerProps.name, headerProps.defaultSortDirection || TableSortDirection.DESC)
        }
        direction={sortBy && sortBy.name === headerProps.name ? direction : undefined}
      />
    );
  };

  return (
    <div className={styles.component} data-testid={testId} data-test-element="table">
      <div className={styles.table}>
        <div
          id="header-box"
          ref={headerBoxRef}
          className={twMerge(
            clsx(
              'overflow-hidden',
              {
                'sticky top-0 z-[1001]': headerSticky,
              },
              headerBoxClassName,
            ),
          )}
          onScrollCapture={handleScrollX}
        >
          <div className="relative">
            <div
              id="header"
              ref={headerRef}
              className={clsx(styles.header, {hidden: header.every(el => !el.title?.length)})}
            >
              {header.map((_, index) => {
                return (
                  <>
                    {lastColumnSticky && index === header.length - 1 && <div className={widthClassNames[index]} />}
                    {((lastColumnSticky && index < header.length - 1) || !lastColumnSticky) && renderHeaderRow(index)}
                  </>
                );
              })}
            </div>
            {lastColumnSticky && <div>{renderHeaderRow(header.length - 1)}</div>}
          </div>
        </div>
        <div ref={contentRef} className="overflow-x-auto overflow-y-hidden w-full" onScrollCapture={handleScrollX}>
          <div className="w-fit min-w-full">{rows}</div>
        </div>
      </div>
    </div>
  );
};
