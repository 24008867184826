import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const intlFunction = (id: string) => {
  return intl.formatMessage({id});
};

export const downloadFromLink = (link: string, fileName: string) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = `${link}`;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getSize = (size: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const value = size > 0 ? `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}` : '0 B';
  return value;
};

const emailValidationRules: {
  errorMessage: string;
  rule: string;
}[] = [
  {
    errorMessage: intl.formatMessage({id: Tr.requiredEmailField}),
    rule: '[a-zA-Z0-9-]{4,}',
  },
  {
    errorMessage: intl.formatMessage({id: Tr.requiredEmailFieldUserName}),
    rule: '^[^\\s@]+@',
  },
  {
    errorMessage: intl.formatMessage({id: Tr.requiredEmailFieldMonkeySign}),
    rule: '[@]',
  },
  {
    errorMessage: intl.formatMessage({id: Tr.requiredEmailFieldDotSign}),
    rule: '[\\.]',
  },
  {
    errorMessage: intl.formatMessage({id: Tr.requiredEmailFieldDomain}),
    rule: '@+[^\\s]+\\.',
  },
  {
    errorMessage: intl.formatMessage({id: Tr.requiredEmailFieldMainDomain}),
    rule: '@+[^\\s]+\\.[^\\s]+',
  },
];

const validateEmailRegex = (errorList: string[], errorMessage: string, emailToValidate: string, validator: RegExp) => {
  if (!validator.test(emailToValidate)) {
    errorList.push(errorMessage);
  }
};

export const emailValidator = (emailToValidate: string): string[] => {
  const errorList: string[] = [];

  for (const rule of emailValidationRules) {
    const validator = new RegExp(rule.rule);

    validateEmailRegex(errorList, rule.errorMessage, emailToValidate, validator);
  }

  return errorList;
};

export const returnStringFromRegex = (regex: RegExp, stringToParse: string) => {
  return stringToParse.match(regex) || '';
};

export const checkFileSizeExceeded = (fileSize: number): boolean => fileSize > 104857600;

export const checkIsFolder = async (file: File): Promise<boolean> => {
    if (file.type !== '' || file.size % 4096 !== 0 || file.size > 4 * 1024 * 1024) return false;
  
    return new Promise<boolean>(resolve => {
      const reader = new FileReader();
  
      reader.onload = () => resolve(false);
      reader.onerror = () => resolve(true);
  
      reader.readAsText(file);
    });
  };