import {usePageTitle} from '../../../common';
import {Header} from '../../../root/components/Header';
import {intlFunction} from '../../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';

const SharesPage = () => {
  const {setPageTitle} = usePageTitle();

  setPageTitle(intlFunction(Tr.shares));

  return (
    <div className="p-[28px] flex flex-col gap-2 h-auto overflow-[hidden]">
      <Header>{intlFunction(Tr.shares)}</Header>
    </div>
  );
};

export default SharesPage;
