import {IMask} from "react-imask";
import {DateObj, dateObjToDate, dateToYearMonthString, dateToYearString, getOffsetDate, isAfterDate, isBeforeDate} from "../InputDateRange/Calendar.helper";
import {FactoryOpts} from "imask/esm/index";
import {DATE_Y, DATE_MY} from "../../../types";

export const toDateMonthAndYearStr = ({year, month}: DateObj): DATE_MY => {
    return `${`0${month}`.slice(-2)}-${year}` as DATE_MY;
  };
  
  export const toDateYearStr = ({year}: DateObj): DATE_Y => {
    return `${year}` as DATE_Y;
  };

  type fixDateMonthAndYearValueFn = {
    (nextValue: string, _: {minDate: Date | DateObj; maxDate: Date | DateObj; dayOffset?: number}): DATE_MY;
  };
  
  type fixDateYearValueFn = {
    (nextValue: string, _: {minDate: Date | DateObj; maxDate: Date | DateObj; dayOffset?: number}): DATE_Y;
  };

  export const fixDateMonthAndYearValue: fixDateMonthAndYearValueFn = (nextValue, {minDate, maxDate, dayOffset = 1}) => {
    const valueFix = nextValue.substring(0, 7);
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
  
    const [year, month] = valueFix.split('-').map(s => parseInt(s, 10) || 1);
    const nextDateMin = new Date(year, month - 1, 1);
    const nextDateMax = new Date(year, month, 0);
    let nextDate = nextDateMin;
  
    if (isBeforeDate(nextDateMax, minDateRef)) {
      nextDate = getOffsetDate(minDateRef, dayOffset);
    }
  
    if (isAfterDate(nextDateMin, maxDateRef)) {
      nextDate = getOffsetDate(maxDateRef, -dayOffset);
    }
  
    return dateToYearMonthString(nextDate) as DATE_MY;
  };
  
  export const fixDateYearValue: fixDateYearValueFn = (nextValue, {minDate, maxDate, dayOffset = 1}) => {
    const valueFix = nextValue.substring(0, 4);
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
  
    const year = parseInt(valueFix, 10);
    const nextDateMin = new Date(year, 0, 1);
    const nextDateMax = new Date(year + 1, 0, 0);
    let nextDate = nextDateMin;
  
    if (isBeforeDate(nextDateMax, minDateRef)) {
      nextDate = getOffsetDate(minDateRef, dayOffset);
    }
  
    if (isAfterDate(nextDateMin, maxDateRef)) {
      nextDate = getOffsetDate(maxDateRef, -dayOffset);
    }
  
    return dateToYearString(nextDate) as DATE_Y;
  };

  export const getIMaskConfigMonthAndYear = ({
    value,
    minDate,
    maxDate,
  }: {
    value: string;
    minDate: Date | DateObj;
    maxDate: Date | DateObj;
  }): FactoryOpts => {
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
    return {
      mask: /^[0,1]$|^0[1-9]$|^1[0,1,2]$|^0[1-9]-$|^1[0,1,2]-$|^(0[1-9]|1[0,1,2])-(1|2)$|^(0[1-9]|1[0,1,2])-(19|20)\d{0,2}$/,
      autofix: true,
      format: (val: string) => {
        return val as DATE_MY;
      },
      parse: (val: string) => {
        return val;
      },
    };
  };
  
  export const getIMaskConfigYear = ({
    value,
    minDate,
    maxDate,
  }: {
    value: string;
    minDate: Date | DateObj;
    maxDate: Date | DateObj;
  }): FactoryOpts => {
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
    return {
      mask: IMask.MaskedNumber,
      min: minDateRef.getFullYear(),
      max: maxDateRef.getFullYear(),
      maxLength: 4,
      placeholderChar: 'R',
      autofix: true,
      scale: 0,
      parse: (str: string) => {
        return parseInt(str, 10);
      },
      format: (year: number | null) => {
        if (!year) {
          return '';
        }
        return year.toString() as DATE_Y;
      },
    };
  };
  