import {action, computed, makeObservable, observable, ObservableMap} from 'mobx';
import {IBaseFilterState} from './IBaseFilterState';
import {DateRangeFilterType, ExtendedDateRangeFilterType, ValueType} from '../../modules/layout/components/Filters/FiltersState';
import {MultiSelectModel} from '../../modules/common/components/MultiSelect/MultiSelect';
import {BaseFilterStateStorage} from './BaseFilterStateStorage';
import {isEmptyObj} from '../../modules/common/helpers/helperFunctions';
import {dateParser} from '../../modules/common/helpers/dateParser';
import {IBaseFilterStateStorage} from './IBaseFilterStateStorage';
import {invoiceCurrency} from '../../modules/common';
import dayjs from 'dayjs';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';
import {convertToMultiSelectType} from '../../modules/common/helpers/baseFilterHelpers';

export enum DateRangeFilterEnum {
  from = 'DateFrom',
  to = 'DateTo',
  specific = 'Specific',
}

export enum DateRangeFilterEnumPosting {
  from = 'PostingCreatedAtFrom',
  to = 'PostingCreatedAtTo',
  specific = 'PostingCreatedAtSpecific',
}

export enum DateRangeFilterEnumIssueDate {
  from = 'DateFrom',
  to = 'DateTo',
  specific = 'DateOfIssue',
}

export enum DateRangeFilterEnumKsefIssueDate {
  from = 'invoiceKsefIssueDateFrom',
  to = 'invoiceKsefIssueDateTo',
  specific = 'invoiceKsefIssueDate',
}

export enum RangeFilterEnum {
  from = 'vatValueFrom',
  to = 'vatValueTo',
  specific = 'specific',
}

export enum RangeFilterEnumNetValue {
  from = 'netValueFrom',
  to = 'netValueTo',
  specific = 'netValueSearchAmount',
}

export enum RangeFilterEnumVatValue {
  from = 'vatValueFrom',
  to = 'vatValueTo',
  specific = 'vatValueSearchAmount',
}

export enum RangeFilterEnumGrossValue {
  from = 'grossValueFrom',
  to = 'grossValueTo',
  specific = 'grossValueSearchAmount',
}

export enum FilterScopeType {
  String = 'String',
  Number = 'Number',
  Date = 'Date',
  Enum = 'Enum',
  Range = 'Range',
  UUIDs = 'UUIDs',
  Boolean = 'Boolean',
}

export type ListFilterType<K extends string = string> = Array<{value: filterType; key: K}>;
export type filterType<T = any> = {
  values: Array<T>;
  type?: FilterScopeType;
  isActive: boolean;
  pills?: MultiSelectModel[];
  isRangeFilter?: boolean;
  isPageFilter?: boolean;
  related?: IRelatedFilter;
  translationKey?: string;
};

export interface IRelatedFilter {
  parentName: string;
  key: string;
}

export interface TranslatedFilter {
  values: MultiSelectModel[];
  translationKey?: string;
}

export abstract class BaseFilterState<T, K extends string> implements IBaseFilterState<T, K> {
  parent: T;

  @observable
  activeFilters = new ObservableMap<K, filterType>();

  @observable
  pillsList = new ObservableMap<K | DateRangeFilterEnum | RangeFilterEnum, TranslatedFilter>();

  @observable
  datePills = new Map<DateRangeFilterEnum, TranslatedFilter>();

  @observable
  hasError = false;

  defaultFilters: ListFilterType<K> = [];

  storage: IBaseFilterStateStorage<T, K>;

  constructor(p: T) {
    makeObservable(this);
    this.parent = p;
    this.storage = new BaseFilterStateStorage();
  }

  /*Gets page filters from default filters*/
  @computed
  get pageFilters() {
    return this.defaultFilters.filter(f => f.value.isPageFilter).map(f => f.key as K);
  }

  /**Creating an array for query, only non-empty and active*/
  @computed
  get getActiveFilters() {
    const filters = {};
    this.activeFilters.forEach((value: filterType, key: string) => {
      if (value.values.length > 0 && value.isActive) {
        //@ts-ignore
        filters[key] = value;
      }
    });
    return filters;
  }

  /**Creating an array for query, only non-empty and active - new way of filtering data*/
  @computed
  get getActiveFiltersForQuery(): Record<string, filterType> {
    const filters = {};
    this.activeFilters.forEach((value: filterType, key: string) => {
      if (value.values.length > 0 && value.isActive) {
        //@ts-ignore
        filters[key] = value;
      }
    });
    return filters;
  }

  /**Watching users changes on filters*/
  @computed
  get getChangedFilters() {
    const filters = {};
    this.activeFilters.forEach((value: filterType, key: string) => {
      if (value.values.length > 0) {
        if (!Object.values(value.values[0]).every(el => el === undefined)) {
          // @ts-ignore
          filters[key] = value.values;
        }
      }
    });
    return filters;
  }

  /**Watching users changes on filters - new way of filtering data*/
  @computed
  get getChangedFiltersForQuery(): Record<string, filterType> {
    const filters = {};
    this.activeFilters.forEach((value: filterType, key: string) => {
      if (value.values.length > 0) {
        if (!Object.values(value.values[0]).every(el => el === undefined)) {
          // @ts-ignore
          filters[key] = value;
        }
      }
    });
    return filters;
  }

  /**Changing all modified filters to active - action on btn demand*/
  @action.bound
  public startFiltering() {
    this.activeFilters.forEach(e => {
      if (e.values.length > 0 && !isEmptyObj(e.values[0])) e.isActive = true;
    });
    this.createPills();
  }

  /**Setting single filter to active and save changes on localStorage*/
  @action.bound
  public setActiveFiltersWithStorage(key: K, values: filterType) {
    this.setActiveFilters(key, values);
    this.storage.saveToStorage(this.activeFilters);
  }

  /*Setting single filter to active without saving on localStorage
  Gets default state of filter and updates its value*/
  @action.bound
  public setActiveFilters(key: K, values: filterType) {
    const existingDefaultFilter = this.defaultFilters.find(f => f.key === key);
    if (existingDefaultFilter == null) throw new Error('Cannot find default implementation of filter');
    this.activeFilters.set(key, {...existingDefaultFilter.value, ...values});
  }

  /**Building pills depending on each active filter*/
  @action.bound
  public createPills() {
    this.pillsList.clear();
    this.activeFilters.forEach((e, k) => {
      if (e.pills && e.isActive && !e.isRangeFilter && e.values.length > 0) {
        this.pillsList.set(k as K, {values: e.pills, translationKey: e.translationKey});
      }
      if (e.isRangeFilter && e.isActive && e.pills && e.values.length > 0) {
        e.pills.forEach(p =>
          e.type === FilterScopeType.Date
            ? this.pillsList.set(p.value as DateRangeFilterEnum, {
                values: [{key: k, value: dateParser(p.key)}],
                translationKey: e.translationKey,
              })
            : this.pillsList.set(p.value as RangeFilterEnum, {
                values: [{key: k, value: invoiceCurrency.format(Number.parseFloat(p.key))}],
                translationKey: e.translationKey,
              }),
        );
      }
    });
  }

  /**Clearing all side menu filters - action on button*/
  @action.bound
  public handleClearMenuFilter() {
    this.activeFilters.forEach((v, k) =>
      this.setActiveFilters(k, {
        values: [],
        isActive: false,
      }),
    );
  }

  /**Removing single filter - changing status to NOT ACTIVE + removing it's pill*/
  @action.bound
  public clearSingleFilter(filterKey: K | string, filterField?: string | number, filterValue?: string) {
    const keys = Array.from(this.activeFilters.keys());
    const filterName = (keys.find(e => filterKey.toLowerCase() === (e.toLowerCase())) ?? filterField) as K;
    const filterByKey = this.activeFilters.get(filterName);

    if (filterByKey !== undefined) {
      if (!filterByKey.isRangeFilter) {
        const {values: filter, pills} = this.activeFilters.get(filterName)!;
        if (!!filter && filter.length > 1 && !!pills && pills.length > 1 && filterField !== undefined) {
          const filterToRemove =
            filter.indexOf(filterField) === -1 ? filter.indexOf(filterValue) : filter.indexOf(filterField);

          filter.splice(filterToRemove, 1);

          const pillToRemove =
            pills.findIndex(item => item.key === filterField) === -1
              ? pills.findIndex(item => item.value === filterField)
              : pills.findIndex(item => item.key === filterField);

          pills.splice(pillToRemove, 1);

          this.setActiveFilters(filterName, {values: filter, isActive: true, pills});
          this.storage.saveToStorage(this.activeFilters);
        } else if (filter.length <= 1 || filterField === undefined) {
          this.setActiveFilters(filterName, {...filterByKey, values: [], isActive: false, pills: []});
          this.pillsList.clear();
          this.storage.removeFromStorage(this.activeFilters);
        }
      } else {
        this.datePills.delete(filterField as DateRangeFilterEnum);

        Object.keys(filterByKey.values[0]).map(key => {
          if (filterKey.toLowerCase().includes(key.toLowerCase())) {
            filterByKey.values[0][key] = undefined;
          }
        });
        filterByKey.pills !== undefined && filterByKey.pills?.length > 1
          ? this.setActiveFilters(filterName, {
              values: filterByKey.values,
              isActive: filterByKey.isActive,
              pills: filterByKey.pills?.filter(p => !filterKey.toLowerCase().includes(p.value.toLowerCase())),
            })
          : this.setActiveFilters(filterName, {values: [{to: undefined, from: undefined}], isActive: false});
        this.storage.saveToStorage(this.activeFilters);
      }
    }
    this.createPills();
  }

  /**Special action to create Date filters - REFACTOR NEEDED ASAP*/
  @action.bound
  public setRangeFilter(
    filterKey: K,
    value: ExtendedDateRangeFilterType | DateRangeFilterType | ValueType | undefined,
    enumKey:
      | typeof DateRangeFilterEnumPosting
      | typeof DateRangeFilterEnum
      | typeof RangeFilterEnum
      | typeof RangeFilterEnumNetValue
      | typeof RangeFilterEnumVatValue
      | typeof RangeFilterEnumGrossValue
      | typeof DateRangeFilterEnumIssueDate
      | typeof DateRangeFilterEnumKsefIssueDate,
  ) {
    let filterName = '';
    Array.from(this.activeFilters.keys()).forEach(e =>
      filterKey.toLowerCase().includes(e.toLowerCase()) ? (filterName = e as K) : '',
    );
    const filterByKey = this.activeFilters.get(filterName as K);
    if (value !== undefined) {
      if (value.specific !== undefined) {
        this.setActiveFilters(filterKey, {
          ...filterByKey,
          values: [
            {
              specific: value.specific,
            },
          ],
          isActive: false,
          pills: [
            {
              value: enumKey.specific,
              key: value.specific?.toString() ?? '',
            },
          ],
        });
      } else {
        let fromValue = null,
          toValue = null;

        if (typeof value.from === 'number' || typeof value.to === 'number') {
          fromValue = typeof value.from === 'number' ? value.from : undefined;
          toValue = typeof value.to === 'number' ? value.to : undefined;
        } else {
          fromValue = [value.from, dayjs(value.to).add(-1, 'month').toDate()].find(x => x)?.toString();
          toValue = [value.to, dayjs().toDate()].find(x => x)?.toString();
        }

        const rangeValue = {
          from: fromValue,
          to: toValue,
          rangeType: (value as ExtendedDateRangeFilterType).rangeType,
        };

        const pills = [];

        const fromPill = {
          value: enumKey.from,
          key: rangeValue.from?.toString() ?? '',
        };
        const toPill = {
          value: enumKey.to,
          key: rangeValue.to?.toString() ?? '',
        };

        if (rangeValue.from) pills.push(fromPill);
        if (rangeValue.to) pills.push(toPill);

        this.setActiveFilters(filterKey, {
          ...filterByKey,
          values: [rangeValue],
          isActive: false,
          pills: pills,
        });
      }
    } else {
      this.setActiveFilters(filterKey, {
        ...filterByKey,
        values: [{from: undefined, to: undefined}],
        isActive: false,
        pills: [],
      });
    }
  }

  @action.bound
  public setHasError(hasError: boolean) {
    this.hasError = hasError;
  }

  /**MultiSelectModel action depended on key/value required on query*/
  @action.bound
  public handleSelectByValue(selected: MultiSelectModel[], filterType: K) {
    const filterExist = this.activeFilters.get(filterType);
    const filteredSelected = selected.filter(({value, key}) => value !== '');
    this.setActiveFilters(filterType, {
      values: filteredSelected?.map(x => x.value.toString()),
      isActive: filterExist !== undefined ? filterExist?.isActive : false,
      pills: filteredSelected,
    });
  }

  @action.bound
  public handleSelectByKey(selected: MultiSelectModel[], filterType: K) {
    const filterExist = this.activeFilters.get(filterType);
    this.setActiveFilters(filterType, {
      values: selected?.map(x => x.key.toString()),
      isActive: filterExist !== undefined ? filterExist?.isActive : false,
      pills: selected,
    });
  }

  @action.bound
  public handleSelectWithTypeConversion = (selected: (DropdownListOption<any> | undefined)[], filterType: K) => {
    this?.setActiveFilters(filterType, {
      values: selected?.map(x => x?.value.toString()),
      isActive: this.activeFilters.get(filterType)?.isActive ?? false,
      pills:
        convertToMultiSelectType(
          selected,
          sel => ({
            value: sel?.label ?? '',
            key: sel?.value ?? '',
          }),
          true,
        ) ?? [],
    });
  };

  /**Reset all filters to default/localStorage state*/
  @action.bound
  public resetFilterToStorage() {
    this.setDefaultValues();
  }

  /**Delete all SideMenu Filters from localStorage - action on btn 'Clear Filters'*/
  @action.bound
  public resetStorageFilters(filters: K[]) {
    filters.forEach(f => this.storage.removeFromStorage(this.activeFilters));
  }

  /**
   * Method to be used in derived class constructor.
   * Needed to ensure that setup methods are called with values from
   * derived class overrides instead of default values in base class
   */
  protected abstract setDefaultValues(): void;
}
