import {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import {InputDate, InputDateWidth, InputWidth, Label, RadioButtonGroup} from '@symfonia/brandbook';
import {DATE_DMY} from '../../../../../../libs/brandbook/src/external/types';
import {europeanDateFormatter} from 'libs/symfonia-ksef-components/src/helpers/europeanDateFormatter';
import {TogglableGroupOrientation} from 'libs/brandbook/src/internal/components/Togglable/TogglableGroup';
import {DATE_MY, DATE_Y} from 'libs/symfonia-ksef-components/src/types';
import {InputDateYear} from './InputDateYear';
import {InputDateMonthAndYear} from './InputDateMonthAndYear';

export interface ExtendedDatePickerProps extends PropsWithChildren {
  setDate: (value?: {from?: Date; to?: Date; specific?: Date, rangeType?: InputDateMode}) => void;
  startDate?: Date;
  specificDate?: Date;
  endDate?: Date;
  inputMode?: InputDateMode;
  singleDateLabel?: string;
  singleDateInputLabel: string;
  rangeDateInputLabel?: string;
  rangeDateFromLabel?: string;
  rangeDateToLabel?: string;
  setValidationError?: (hasError: boolean) => void;
  calendarModeDay?: string;
  calendarModeMonth?: string;
  calendarModeYear?: string;
  testId?: string;
}

export enum InputDateMode {
  Day,
  Month,
  Year,
}

export const ExtendedDatePicker: FC<ExtendedDatePickerProps> = ({
  startDate,
  endDate,
  setDate,
  inputMode,
  specificDate,
  singleDateLabel,
  singleDateInputLabel,
  rangeDateInputLabel,
  rangeDateFromLabel,
  rangeDateToLabel,
  setValidationError,
  calendarModeDay,
  calendarModeMonth,
  calendarModeYear,
  testId,
}) => {
  const [hasError, setHasError] = useState(false);
  const [inputViewMode, setInputViewMode] = useState(inputMode ?? InputDateMode.Day);
  const specificDateValue = specificDate && dayjs(specificDate)?.format('DD-MM-YYYY') as DATE_DMY;

  const updateInputViewMode = useCallback((mode: InputDateMode) => {
    setDate(undefined);
    setHasError(false);
    setValidationError?.(false);
    setInputViewMode(mode);
  }, [setInputViewMode]);

  const onChangeClearValidation = useCallback(() => {
    setValidationError?.(false);
    setHasError(false);
  }, [setValidationError]);

  useEffect(() => {
    if (!startDate && !endDate) return;
    if (!dayjs(startDate).isBefore(dayjs(endDate))) {
      setValidationError?.(true);
      setHasError(true);
      return;
    }
    onChangeClearValidation();
  }, [startDate, endDate]);

  const handleOnChangeDay = (date: DATE_DMY, type: 'FROM' | 'TO') => {
    const newDate = europeanDateFormatter(date);
    setDate({from: type === 'FROM' ? newDate : startDate, to: type === 'TO' ? newDate : endDate, specific: undefined, rangeType: InputDateMode.Day});
  };

  const handleOnChangeMonth = (date: DATE_MY, type: 'FROM' | 'TO') => {
    const [monthStr, yearStr] = date.split('-');

    const month: number = parseInt(monthStr, 10);
    const year: number = (yearStr === undefined || yearStr.length < 4 ? NaN : parseInt(yearStr, 10));
    let newDate =  new Date(year, type === 'FROM' ? month : month + 1, type === 'FROM' ? 1 : 0);
    const now = dayjs().toDate();
    if (newDate > now) {
      newDate =  type === 'FROM' ? new Date(now.getFullYear(), now.getMonth(), 1) : now;
    }
    setDate({from: type === 'FROM' ? newDate : startDate, to: type === 'TO' ? newDate : endDate, specific: undefined, rangeType: InputDateMode.Month});
  };

  const handleOnChangeYear = (date: DATE_Y, type: 'FROM' | 'TO') => {
    const year: number = (date === undefined || date.length < 4 ? NaN : parseInt(date, 10));
    let newDate =  new Date(type === 'FROM' ? year : year + 1, 0, type === 'FROM' ? 1 : 0);
    const now = dayjs().toDate();
    if (newDate > now) {
      newDate =  type === 'FROM' ? new Date(now.getFullYear(), 0, 1) : now;
    }
    setDate({from: type === 'FROM' ? newDate : startDate, to: type === 'TO' ? newDate : endDate, specific: undefined, rangeType: InputDateMode.Year});
  };
  
  const options = [
    {
      label: calendarModeDay ?? "Dzień - Miesiąc - Rok",
      value: InputDateMode.Day,
    },
    {
      label: calendarModeMonth ?? "Miesiąc - Rok",
      value: InputDateMode.Month,
    },
    {
      label: calendarModeYear ?? "Rok",
      value: InputDateMode.Year,
    },
  ];

  return (
    <div>
      <InputDate
        label={singleDateLabel}
        placeholder={singleDateInputLabel}
        width={InputWidth.FULL}
        value={specificDateValue || '' as DATE_DMY}
        onChange={newDate => {
          const europeanDate = europeanDateFormatter(newDate);
          europeanDate !== specificDate ? setDate({specific: europeanDate}) : setDate(undefined);
          setHasError(false);
          setValidationError?.(false);
        }}
        testId={`${testId}-SpecificDate`}
      />
      <div className="flex flex-col mt-2 gap-[8px]">
        {rangeDateInputLabel && <Label text={rangeDateInputLabel}/>}
        <RadioButtonGroup 
          orientation={TogglableGroupOrientation.VERTICAL} 
          checkboxes={options} 
          required={false} 
          value={[inputViewMode]}
          onChange={value => updateInputViewMode(value[0] as InputDateMode)}
        />
        <div className="flex gap-[8px]">
          {inputViewMode === InputDateMode.Day && <>
            <InputDate
              width={InputDateWidth.FIT}
              isError={hasError}
              placeholder={rangeDateFromLabel || 'Od'}
              onChange={date => handleOnChangeDay(date, 'FROM')}
              className="mb-[8px]"
              value={startDate !== undefined ? (dayjs(startDate)?.format('DD-MM-YYYY') as DATE_DMY) : ('' as DATE_DMY)}
              testId={`${testId}-DateFrom`}
              maxDate={dayjs().toDate()} />
            <InputDate
              width={InputDateWidth.FIT}
              isError={hasError}
              placeholder={rangeDateToLabel || 'Do'}
              onChange={date => handleOnChangeDay(date, 'TO')}
              className="mb-[8px]"
              value={endDate !== undefined ? (dayjs(endDate)?.format('DD-MM-YYYY') as DATE_DMY) : ('' as DATE_DMY)}
              testId={`${testId}-DateTo`}
              minDate={startDate ? dayjs(startDate).toDate() : undefined} />
          </>}
          {inputViewMode === InputDateMode.Month && <>
            <InputDateMonthAndYear
              width={InputDateWidth.FIT}
              isError={hasError}
              placeholder={rangeDateFromLabel || 'Od'}
              onChange={date => handleOnChangeMonth(date, 'FROM')}
              className="mb-[8px]"
              value={startDate !== undefined ? (dayjs(startDate)?.format('MM-YYYY') as DATE_MY) : ('' as DATE_MY)}
              testId={`${testId}-DateFrom`}
              maxDate={dayjs().toDate()} />
            <InputDateMonthAndYear
              width={InputDateWidth.FIT}
              isError={hasError}
              placeholder={rangeDateToLabel || 'Do'}
              onChange={date => handleOnChangeMonth(date, 'TO')}
              className="mb-[8px]"
              value={endDate !== undefined ? (dayjs(endDate)?.format('MM-YYYY') as DATE_MY) : ('' as DATE_MY)}
              testId={`${testId}-DateTo`}
              minDate={startDate ? dayjs(startDate).toDate() : undefined} />
          </>}
          {inputViewMode === InputDateMode.Year && <>
            <InputDateYear
              width={InputDateWidth.FIT}
              isError={hasError}
              placeholder={rangeDateFromLabel || 'Od'}
              onChange={date => handleOnChangeYear(date.toString() as DATE_Y, 'FROM')}
              className="mb-[8px]"
              value={startDate !== undefined ? (dayjs(startDate)?.format('YYYY') as DATE_Y) : ('' as DATE_Y)}
              testId={`${testId}-DateFrom`}
              maxDate={dayjs().toDate()} />
            <InputDateYear
              width={InputDateWidth.FIT}
              isError={hasError}
              placeholder={rangeDateToLabel || 'Do'}
              onChange={date => handleOnChangeYear(date.toString() as DATE_Y, 'TO')}
              className="mb-[8px]"
              value={endDate !== undefined ? (dayjs(endDate)?.format('YYYY') as DATE_Y) : ('' as DATE_Y)}
              testId={`${testId}-DateTo`}
              minDate={startDate ? dayjs(startDate).toDate() : undefined} />
          </>}
        </div>
      </div>
    </div>
  );
};
