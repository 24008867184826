import {Tr} from '@symfonia-ksef/locales/keys';
import {
  Alert,
  AlertDirection,
  AlertVariant,
  AlertWidth,
  ButtonPrimary,
  ButtonPrimaryWidth,
  DropdownSize,
  DropdownWidth,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  Modal,
  Tooltip,
} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {intl} from '../../root/IntlProvider';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {getSize, intlFunction} from '../utils/utils';
import FilehubFormatSelect from '../components/FilehubFormatSelect';
import {Attachment} from '@symfonia-ksef/graphql';

enum ButtonConfirmState {
  Disabled,
  Active,
  Process,
}

type Props = {
  selectedAttachments: Attachment[];
  state: IFilehubState;
};

const FilehubDownloadAttachmentModal = observer(({selectedAttachments, state}: Props) => {
  const [currentDropdownValue, setCurrentDropdownValue] = useState('formatPDF');
  const [buttonConfirmState, setButtonConfirmState] = useState(ButtonConfirmState.Disabled);
  const [attachmentsSize, setAttachmentsSize] = useState(0);
  const [isMaxSizeExceeded, setIsMaxSizeExceeded] = useState(false);
  const invoiceId = state.invoiceId;
  const invoiceNumber = state.invoiceNumber;
  const zippedAttachmentsFileName = state.zippedAttachmentsFileName;
  const successfulDownloadMultipleAttachmentsRequest = state.successfulDownloadMultipleAttachmentsRequest;
  const hasRequest = successfulDownloadMultipleAttachmentsRequest !== null;
  const {downloadAttachmentsAction} = useFilehubMethods(state);
  const attachmentIds = selectedAttachments.map(attachment => attachment.Id);

  useEffect(() => {
    const size = selectedAttachments.reduce((total, attachment) => (total += attachment.Size), 0);
    setAttachmentsSize(size);
    setIsMaxSizeExceeded(size >= 209715200);
  }, [selectedAttachments]);

  const isLoading = buttonConfirmState === ButtonConfirmState.Process;

  const handleDropdownItemClick = (value: string) => {
    setCurrentDropdownValue(value);
    setButtonConfirmState(ButtonConfirmState.Active);
    state.setSuccessfulDownloadMultipleAttachmentsRequest(null);
  };

  const handleConfirm = async () => {
    setButtonConfirmState(ButtonConfirmState.Process);
    const bothFormats = currentDropdownValue === 'formatPDFandXML';
    const hasPDF = currentDropdownValue === 'formatPDF' || bothFormats;
    const hasXML = currentDropdownValue === 'formatXML' || bothFormats;

    await downloadAttachmentsAction(attachmentIds, invoiceId, hasPDF, hasXML);
    setButtonConfirmState(ButtonConfirmState.Active);
  };

  const handleClose = () => {
    state.setIsDownloadAttachmentModalOpen(false);
  };

  const hideAlert = () => {
    state.setSuccessfulDownloadMultipleAttachmentsRequest(null);
  };

  return (
    <Modal
      className="w-[450px] p-[16px]"
      onClose={handleClose}
      show={state.isDownloadAttachmentModalOpen}
      title={intl.formatMessage({id: Tr.downloadAttachments})}
    >
      <div className="flex flex-col gap-[24px] mb-0">
        <div>
          {intl.formatMessage({id: Tr.invoicePURCHASE})}, <strong>{state.invoiceNumber}</strong>
        </div>
        <div>
          {intl.formatMessage({id: Tr.invoiceFormat})}
          <Tooltip text={intl.formatMessage({id: Tr.formatInvoiceTooltipContent})}>
            <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500} size={IconSize.MD} className="align-middle ml-[4px]"/>
          </Tooltip>
        </div>
        <div>
          <FilehubFormatSelect
            onDropdownItemClick={handleDropdownItemClick}
            dropdownSize={DropdownSize.MD}
            dropdownWidth={DropdownWidth.FULL}
          />
        </div>
        {isMaxSizeExceeded && (
          <Alert
            description={intl.formatMessage(
              {id: Tr.filehubDownloadAttachmentSizeExceededTitle},
              {size: getSize(attachmentsSize)},
            )}
            direction={AlertDirection.VERTICAL}
            title={intlFunction(Tr.filehubDownloadAttachmentSizeExceeded)}
            variant={AlertVariant.ERROR}
            width={AlertWidth.FULL}
          />
        )}
        {hasRequest && (
          <Alert
            description={
              successfulDownloadMultipleAttachmentsRequest
                ? intl.formatMessage({id: zippedAttachmentsFileName})
                : intl.formatMessage(
                    {id: Tr.filehubDownloadedAttachmentFailedDescriptionUnknown},
                    {fileName: invoiceNumber},
                  )
            }
            direction={AlertDirection.VERTICAL}
            onClose={hideAlert}
            title={
              successfulDownloadMultipleAttachmentsRequest
              ? intlFunction(Tr.filehubDownloadedAttachmentsSuccessTitle)
              : intlFunction(Tr.filehubDownloadedAttachmentFailedTitle)
            } 
            variant={successfulDownloadMultipleAttachmentsRequest ? AlertVariant.SUCCESS : AlertVariant.ERROR}
            width={AlertWidth.FULL}
          />
        )}
        {!successfulDownloadMultipleAttachmentsRequest && (
          <div className="flex justify-center mb-0">
            <ButtonPrimary
              loading={isLoading}
              text={intl.formatMessage({id: Tr.download})}
              width={ButtonPrimaryWidth.BASE}
              onClick={handleConfirm}
              disabled={isMaxSizeExceeded}
            />
          </div>
        )}
      </div>
    </Modal>
  );
});

export default FilehubDownloadAttachmentModal;
