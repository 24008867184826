import {Route, Routes} from 'react-router-dom';
import SharesPage from './pages/SharesPage';

const Shares = () => {
  return (
    <Routes>
      <Route path="/" element={<SharesPage/>}/>
    </Routes>
  );
};

export default Shares;
