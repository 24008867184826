import {action, makeObservable, observable} from 'mobx';
import {IEarchiveStateMobx} from '../../../IEArchiveStateMobx';
import {IFilehubState} from './IFilehubState';
import {Attachment} from '@symfonia-ksef/graphql';

export enum MultipleActionsRequestTypeEnum {
  Delete = 'Delete',
  Download = 'Download',
  Private = 'Private',
  Public = 'Public',
}

export type RequestStatus = boolean | null;

export class FilehubState implements IFilehubState {
  @observable
  addAttachmentsList: string[] = [];

  @observable
  attachments: Attachment[] = [];

  @observable
  attachmentsWithoutFilters: Attachment[] = [];

  @observable
  deleteAttachmentsIdsList: string[] = [];

  @observable
  deleteAttachmentsList: Attachment[] = [];

  @observable
  deletedAttachmentsList: Attachment[] = [];

  @observable
  isFileHubOpen = false;

  @observable
  invoiceId = '';

  @observable
  invoiceNumber = '';

  @observable
  zippedAttachmentsFileName = '';

  @observable
  isAddAttachmentModalOpen = false;

  @observable
  selectedAttachmentMenuOpened = false;

  @observable
  selectedAttachmentMenuAnchorEl: HTMLElement | null = null;

  @observable
  selectedRow: Attachment | null = null;

  @observable
  isDeleteAttachmentModalOpen = false;

  @observable
  isDownloadAttachmentModalOpen = false;

  @observable
  isSendAttachmentByLinkModalOpen = false;

  @observable
  areFooterButtonsShown = false;

  @observable
  invoiceIssuerName = '';

  @observable
  invoiceIssuerNIP = '';

  @observable
  multipleActionsRequestType: MultipleActionsRequestTypeEnum | '' = '';

  @observable
  downloadedAttachment: string = '';

  @observable
  downloadAttachmentsList: string[] = [];

  @observable
  search: string = '';

  @observable
  debouncedSearch: string = '';

  // Requests Statuses
  @observable
  successfulAddRequest: RequestStatus = null;

  @observable
  successfulDownloadSingleAttachmentRequest: RequestStatus = null;

  @observable
  successfulDownloadMultipleAttachmentsRequest: RequestStatus = null;

  @observable
  successfulDeleteRequest: RequestStatus = null;

  @observable
  successfulMultipleActionsRequest: RequestStatus = null;

  @observable
  sendByEmailText = '';

  @observable
  _sendByEmailText = '';

  @observable
  emailListToSend: string[] = [];

  @observable
  showGetAttachmentsError: boolean = false;

  @observable
  errorSizeFileList: string[] = [];

  public earchiveState: IEarchiveStateMobx;

  constructor(rootState: IEarchiveStateMobx) {
    this.earchiveState = rootState;
    makeObservable(this);
  }

  @action
  setAddAttachmentsList(list: string[]) {
    this.addAttachmentsList = list;
  }

  @action
  setAttachments(attachments: Attachment[]) {
    this.attachments = attachments;
  }

  @action
  setAttachmentsWithoutFilters(attachments: Attachment[]): void {
    this.attachmentsWithoutFilters = attachments;
  }

  @action
  setDeleteAttachmentsIdsList(list: string[]) {
    this.deleteAttachmentsIdsList = list;
  }

  @action
  setDeleteAttachmentsList(list: Attachment[]) {
    this.deleteAttachmentsList = list;
  }

  @action
  setDeletedAttachmentsList(list: Attachment[]) {
    this.deletedAttachmentsList = list;
  }

  @action
  setIsFileHubOpen(isOpen: boolean): void {
    this.isFileHubOpen = isOpen;
  }

  @action
  setInvoiceId(id: string): void {
    this.invoiceId = id;
  }

  @action
  setInvoiceNumber(number: string): void {
    this.invoiceNumber = number;
  }

  @action
  setZippedAttachmentsFileName(fileName: string): void {
    this.zippedAttachmentsFileName = fileName;
  }

  @action
  setIsAddAttachmentModalOpen(isOpen: boolean): void {
    this.isAddAttachmentModalOpen = isOpen;
  }

  @action
  setSelectedInvoiceMenuAnchorEl(selectedAttachmentMenuAnchorEl: HTMLElement | null): void {
    this.selectedAttachmentMenuAnchorEl = selectedAttachmentMenuAnchorEl;
  }

  @action
  setSelectedInvoiceMenuOpened(selectedAttachmentMenuOpened: boolean): void {
    this.selectedAttachmentMenuOpened = selectedAttachmentMenuOpened;
  }

  @action
  setSelectedRow(selectedRow: Attachment | null): void {
    this.selectedRow = selectedRow;
  }

  @action
  setIsDeleteAttachmentModalOpen(isOpen: boolean): void {
    this.isDeleteAttachmentModalOpen = isOpen;
  }

  @action
  setIsDownloadAttachmentModalOpen(isOpen: boolean): void {
    this.isDownloadAttachmentModalOpen = isOpen;
  }

  @action
  setIsSendAttachmentByLinkModalOpen(isOpen: boolean): void {
    this.isSendAttachmentByLinkModalOpen = isOpen;
  }

  @action
  setAreFooterButtonsShown(areShown: boolean): void {
    this.areFooterButtonsShown = areShown;
  }

  @action
  setMultipleActionsRequestType(requestType: MultipleActionsRequestTypeEnum): void {
    this.multipleActionsRequestType = requestType;
  }

  @action
  setDownloadedAttachment(attachmentName: string): void {
    this.downloadedAttachment = attachmentName;
  }

  @action
  setDownloadAttachmentsList(list: string[]): void {
    this.downloadAttachmentsList = list;
  }

  // Request Status Methods
  @action
  setSuccessfulAddRequest(isSuccessful: RequestStatus): void {
    this.successfulAddRequest = isSuccessful;
  }

  @action
  setSuccessfulDeleteRequest(isSuccessful: RequestStatus): void {
    this.successfulDeleteRequest = isSuccessful;
  }

  @action
  setSuccessfulDownloadSingleAttachmentRequest(isSuccessful: RequestStatus): void {
    this.successfulDownloadSingleAttachmentRequest = isSuccessful;
  }

  @action
  setSuccessfulDownloadMultipleAttachmentsRequest(isSuccessful: RequestStatus): void {
    this.successfulDownloadMultipleAttachmentsRequest = isSuccessful;
  }

  @action
  setSuccessfulMultipleActionsRequest(isSuccessful: RequestStatus): void {
    this.successfulMultipleActionsRequest = isSuccessful;
  }

  @action
  clearAllRequestAlerts(): void {
    this.successfulAddRequest = null;
    this.successfulDeleteRequest = null;
    this.successfulDownloadSingleAttachmentRequest = null;
    this.successfulDownloadMultipleAttachmentsRequest = null;
    this.successfulMultipleActionsRequest = null;
  }

  @action
  setInvoiceIssuerName(name: string): void {
    this.invoiceIssuerName = name;
  }

  @action
  setInvoiceIssuerNIP(nip: string): void {
    this.invoiceIssuerNIP = nip;
  }

  @action
  setSendByEmailText(emailText: string): void {
    this.sendByEmailText = emailText;
  }

  @action 
  _setSendByEmailText(emailText: string): void {
    this._sendByEmailText = emailText;
  }

  @action
  setSearch(newValue: string) {
    this.search = newValue;
  }

  @action
  setDebouncedSearch(newValue: string) {
    this.debouncedSearch = newValue;
  }

  @action
  setEmailListToSendList(emailList: string[]): void {
    this.emailListToSend = emailList;
  }

  @action
  setShowGetAttachmentsError(show: boolean): void {
    this.showGetAttachmentsError = show;
  }

  @action
  setErrorSizeFileList(list: string[]): void {
    this.errorSizeFileList = list;
  }
}
